// Variables
$convivialColor: rgba(148, 71, 148, 0.44);
$convivialColor2: rgba(164, 28, 164, 0.75);

//classes
.bg-dark{
    background: #333;
    color: white;
}


// -----------
// Extra small screen / phone
$screen-xs: 480px;

// Small screen / tablet
$screen-sm: 768px;

// Medium screern / desktop
$screen-md: 992px;

// Large screen / wide desktop
$screen-lg: 1200px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);