@mixin mediaquerries ($media) {
    @if $media == xs {
        @media (max-width: $screen-xs-max) {
            @content;
        }
    }@else if $media == sm {
        @media (min-width: $screen-xs-max) and (max-width: $screen-sm-max) {
            @content;
        }
    }@else if $media == md {
        @media (min-width: $screen-sm-max) and (max-width: $screen-md-max) {
            @content;
        }
    }@else if $media == lg {
        @media (min-width: $screen-md-max) {
            @content;
        }
    }
}
